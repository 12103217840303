import { Component, AfterViewInit, OnInit, OnDestroy, Renderer2  } from '@angular/core';
import * as SmartAssist from '../../../node_modules/zpf-smart-assist/src/smartAssist.m.js';
import { HttpService } from '../shared/services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smart-assist',
  templateUrl: './smart-assist.component.html',
  styleUrls: ['./smart-assist.component.scss']
})

export class SmartAssistComponent {
  
  constructor(private renderer: Renderer2,
    private httpService: HttpService,
    private router: Router
  ) {}
  isVisible = true; 
  
  handleChatError(error: any): void {
    this.httpService.getRequest('get_user_details').subscribe((user) => {
      console.log('Check for Login')
    }, (error) => {
      console.log('something went wrong ', error);
  });
  }

  // Method to show Smart Assist
  show(): void {
    this.isVisible = true;
    const elements = document.getElementsByClassName('zs-icon-close');
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }
  }

  // Method to hide Smart Assist
  hide(): void {
    this.isVisible = false;
  }
}
// End //
