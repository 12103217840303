import { Component, HostListener, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { GuidedTour, Orientation, GuidedTourService } from 'ngx-guided-tour';
import { AppState } from './shared/services/app-state';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpService } from './shared/services/http.service';
import { Globals } from './shared/constants/globals';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UserDetailsService } from './shared/services/user-details.service';
import SmartAssistJS from './smart-assist/smart-assist-custom.m.js';
import SmartAssist from 'node_modules/zpf-smart-assist/src/smartAssist.m.js';
import { SmartAssistComponent } from './smart-assist/smart-assist.component';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  public title = 'affinity-monitor';
  private appStateSubscription: Subscription;
  public isTourRestarted = false;
  public previousPage = '';
  public highLight = false;
  @ViewChild(SmartAssistComponent) smartAssistComponent!: SmartAssistComponent;

  isSmartAssistVisible = false;


  ngOnInit(): void {
    // Listen to route changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.previousPage = this.activeRoute;
        this.isSmartAssistVisible = false; // Hide Smart Assist on navigation
      }
    });
  }

  toggleSmartAssist(): void {
    console.log('Toggling Smart Assist...');
    this.isSmartAssistVisible = !this.isSmartAssistVisible;
  
    if (this.isSmartAssistVisible) {
      console.log('Smart Assist is now visible.');
      this.previousPage = this.router.url;
      this.smartAssistComponent.show();
      this.activeRoute = '/smart-assist'; 
      this.appState.setGlobalState('activeRoute', this.activeRoute);
    } else {
      console.log('Hiding Smart Assist.');
      this.smartAssistComponent.hide();
      const navigateTo = this.previousPage || '/upload-data';
      this.navigate(navigateTo);
    }

    console.log('isSmartAssistVisible:', this.isSmartAssistVisible);
    console.log('activeRoute:', this.activeRoute);
  }
  

  // toggleSmartAssist(): void {
  //   this.isSmartAssistVisible = !this.isSmartAssistVisible;

  //   if (this.isSmartAssistVisible) {
  //     this.previousPage = this.router.url;
  //     this.smartAssistComponent.show();
  //     this.activeRoute = '/smart-assist';
  //   } else {
  //     this.smartAssistComponent.hide();
  //     this.navigate(this.previousPage || '/upload-data')
  //   }
  // }

  public dashboardTour: GuidedTour = {
    tourId: 'affinity-tour',
    useOrb: false,
    preventBackdropFromAdvancing: true,
    minimumScreenSize: 768,
    steps: [
      {
        title: 'Download Reports',
        selector: '.ant-menu-item-selected',
        content: 'Allows downloading of AffinityMonitor and AccessMonitor reports',
        orientation: Orientation.BottomLeft,
      },
      {
        title: 'User Account Management',
        selector: '.user-icon',
        content: 'Options to manage your account (email settings, passwords, etc.)',
        orientation: Orientation.BottomRight,
      },
      {
        title: 'Settings',
        selector: '.settings-icon',
        content: 'Tweak general website settings',
        orientation: Orientation.BottomRight,
      },
      {
        title: 'Help',
        selector: '.help-icon',
        content: 'Contains the FAQs, User Guides, and Suggestion Form',
        orientation: Orientation.BottomRight,
      },
    ],
    skipCallback: (stepSkippedOn) => this.onBoardSkipped(stepSkippedOn),
    completeCallback: () => this.onBoardSkipped(0),
  };
  public isOnBoarded = true;
  public activeRoute = '';
  public showDropdown = false;
  public adminPendingCount = 0;
  public setRoute = false;
  public userDetailsCalled = false;
  public pendingCountCalled = false;
  public uploadCount = 0;

  constructor(
    private guidedTourService: GuidedTourService,
    private appState: AppState,
    private router: Router,
    private httpService: HttpService,
    public globals: Globals,
    private notification: NzNotificationService,
    private UserService: UserDetailsService,
    private cdr: ChangeDetectorRef
  ) {
    try {
      SmartAssistJS.register();
    } catch (error) {
      console.error('Smart Assist JS registration error:', error);
    }
    // Register Smart Assist after user details are loaded
    try {
      SmartAssist.register();
    } catch (error) {
      console.error('Smart Assist registration error:', error);
    }
    this.appStateSubscription = AppState.appState.subscribe((val) => {
      if (this.globals.userDetail) {
        if (typeof val === 'string') {
          val = JSON.parse(val);
        }

        if (!val || !val.activeRoute && !this.setRoute) {
          // this.navigate('/subscriptions');
          this.navigate('/upload-data');
        }

        if (this.router.url === '/' && this.activeRoute !== '/upload-data') {
          this.navigate('/upload-data');
          // this.navigate('/subscriptions');
        }

        if (val) {
          if (val.activeRoute === '/faq' && this.router.url !== '/') {
            this.highLight = true;
          } else {
            this.highLight = false;
          }

          if (val.activeRoute !== this.activeRoute) {
            this.activeRoute = val.activeRoute;
          }

          if (val.showDropdown) {
            this.showDropdown = val.showDropdown;
          }

          if (val.offset === 0) {
            this.uploadCount = 0;
          }
        }

        if (!this.pendingCountCalled && this.globals.userDetail.role === 'admin' || this.globals.userDetail.role === 'manager') {
          this.getPendingCount();
        }

      } else {
        if (!this.userDetailsCalled) {
          this.getUserDetails();
        }
      }
    });
  }

  public onOnboardingClose(event): void {
    this.isOnBoarded = true;
    this.appState.setGlobalState('isOnBoarded', true);
  }

  public onBoardSkipped(stepSkippedOn): void {
    if (this.isTourRestarted) {
      this.router.navigateByUrl(this.previousPage);
      this.appState.setGlobalState('activeRoute', this.previousPage);
      this.isTourRestarted = false;
    } else {
      this.appState.setGlobalState('isOnBoarded', true);
    }
  }

  public restartTour(): void {
    this.previousPage = this.router.url;
    this.isTourRestarted = true;
    this.router.navigateByUrl('/upload-data');
    this.appState.setGlobalState('activeRoute', '/upload-data');
    // this.router.navigateByUrl('/subscriptions');
    // this.appState.setGlobalState('activeRoute', '/subscriptions');
    setTimeout(() => {
      this.guidedTourService.startTour(this.dashboardTour);
    }, 1000);
  }

  public logout(): void {
    this.appState.clearGlobalState();
    window.location.href = environment.authLogOutUrl;
  }

  public navigate(url): void {
    // Reset Smart Assist visibility if navigating away
    if (this.isSmartAssistVisible) {
      this.isSmartAssistVisible = false;
      this.smartAssistComponent.hide();
    }
    this.setRoute = true;
    this.highLight = true;
    this.activeRoute = url;
    if (url !== '/upload-data') {
      this.notification.remove();
    }
    if (url === '/subscriptions' || url === '/admin' || url === '/dashboard' || url === '/faq') {
      this.appState.setGlobalState('showDrawer', true);
    }
    this.router.navigateByUrl(this.globals.userDetail.client + url);
    this.appState.setGlobalState('activeRoute', url);
  }

  public getHeader(): string {
    let header = '';
    if (this.isSmartAssistVisible) {
      return 'Smart Assist';
    }
    switch (this.activeRoute) {
      case '/download-reports':
        header = 'Download Reports';
        break;
      case '/upload-data':
        header = 'Upload Data';
        break;
      case '/affex':
        header = 'AFFEX';
        break;
      case '/subscriptions':
        header = 'Subscriptions';
        break;
      case '/admin':
        header = 'Admin Panel';
        break;
      case '/faq':
        header = 'Help and FAQs';
        break;
      case '/dashboard':
        header = 'Benchmarking Dashboard';
        break;
      case '/usecases':
        header = 'Use Cases';
        break;
      case '/smart-assist':
        header = 'Smart Assist';
        break;
    }
    return header;
  }

  public hasAccess(page): boolean {
    let access = [];
    if (this.globals.userDetail && this.globals.userDetail.access) {
      access = this.globals.userDetail.access;
    } else {
      access.push('subscriptions');
    }
    if (this.globals.userDetail) {
      if (this.globals.userDetail.role === 'admin' || this.globals.userDetail.role === 'manager') {
        access = ['admin', 'subscriptions', 'upload', 'use cases', 'dashboard'];
      }
    }
    if (this.globals.userDetail && access.includes(page)) {
      return true;
    } else {
      return false;
    }
  }

  public getUserDetails(): void {
    this.userDetailsCalled = true;
    this.appState.passValue({ clientLoading: true });
  
    // Step 1: Get the token from the backend API
    this.httpService.getRequest('get_user_details').subscribe(
      (response: any) => {
        const token = response.token;
        const payload = {
          token: token,
        };
  
        // Step 2: Validate the token using a new validation endpoint
        console.log('before validating payload:-', payload)
        this.httpService.postRequest('validate-token', payload).subscribe(
          (validationResult: any) => {
            if (validationResult.valid) {
              console.log('inside validate token', validationResult)
              const user = response.data; 
              console.log('authenticatrion response :-', response.data)
  
              // Step 3: Set user details and proceed with the existing logic
              this.globals.userDetail = user;
              this.appState.setGlobalState('userDetails', user);
              this.UserService.setUserDetails(user);
              this.isOnBoarded = !this.globals.userDetail.newUser;
              const pathArray = this.router.url.split('/');
              if (pathArray[pathArray.length - 1] !== this.activeRoute) {
                if (pathArray[1] === 'afm') {
                  this.navigate(user.client + this.activeRoute);
                } else {
                  this.navigate(this.activeRoute);
                }
              }
            } else {
              console.error('Invalid token:', validationResult.error);
            }
          },
          (validationError) => {
            console.error('Error validating token:', validationError);
          }
        );
      },
      (error) => {
        console.error('Something went wrong while fetching user details:', error);
      }
    );
  }

  public getPendingCount(): void {
    this.pendingCountCalled = true;
    this.httpService.getRequest('get_pending_count').subscribe((data) => {
      this.adminPendingCount = Number(data.standardTab) + Number(data.customTab);
    });
  }

  @HostListener('window:scroll', ['$event'])
  public scrollHandler(event): void {
    if (this.activeRoute === '/upload-data') {
      if (event.target.offsetHeight + event.target.scrollTop > event.target.scrollHeight) {
        this.uploadCount = this.uploadCount + 20;
        this.appState.setGlobalState('offset', this.uploadCount);
      }
    }
    if (this.showDropdown) {
      this.appState.setGlobalState('showDropdown', false);
    }
  }
}
