import SmartAssistResponse from 'zpf-smart-assist/src/bubble/smartAssistResponse.m.js';

export default class SmartAssistJS extends SmartAssistResponse {
  
  constructor(...args) {
    super(...args);
  }

  getChartOptions(responseOptions) {
    console.log('Response Options:', responseOptions);
    console.log('Categories:', responseOptions.data['categories']);
    console.log('Series:', responseOptions.data['seriesData']);

    const chartType = (responseOptions.data['chartType'])?.toLowerCase() || 'line';
    const categoriesLength = responseOptions.data['categories']?.length || 0;

    return {
      chart: {
        type: chartType,
        ...(categoriesLength > 10 ? { height: categoriesLength * 25 } : {}) 
      },
      title: {
        text: responseOptions.data['title'] || ''
      },
      xAxis: {
        categories: responseOptions.data['categories'] || [],
        title: {
          text: responseOptions.data['xAxisTitle'] || responseOptions.data['xaxisTitle'] || ''
        },
        labels: {
          step: 1,
          style: {
            fontSize: categoriesLength > 10 ? '10px' : '12px'
          }
        }
      },
      yAxis: {
        title: {
          text: responseOptions.data['yAxisTitle'] || responseOptions.data['yaxisTitle'] || ''
        }
      },
      series: responseOptions.data['seriesData'] || []
    };
  }
}