import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public headers = new HttpHeaders({'Content-Type': 'application/json'});
  SERVER_URL = '';
  private client;

  constructor(private httpClient: HttpClient) {
    if (environment.production) {
      this.SERVER_URL = environment.url;
    } else {
      this.SERVER_URL = 'http://localhost:8080/api/';
    }
    const pathArray = window.location.href.split('/');
    this.client = environment.client.find(c => pathArray.includes(c.name));
    console.log('Path array service :- ', pathArray)
    if (this.client) {
      this.headers = new HttpHeaders({'client': this.client.name, 'Content-Type': 'application/json'});
    }
    console.log('client: ', this.client);
  }

  public getRequest(url): Observable<any> {
    return this.httpClient.get(this.SERVER_URL + url, {headers: this.headers});
  }

  public postRequest(url, params, skipHeaders = false): Observable<any> {
    if (skipHeaders) {
      return this.httpClient.post(this.SERVER_URL + url, params);
    } else {
      return this.httpClient.post(this.SERVER_URL + url, params, {headers: this.headers});
    }
  }

  public postToUrl(url, params): Observable<any> {
    const testHeaders = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    return this.httpClient.post(url, params, {observe: 'response'}).pipe(
      switchMap(res => res.status === 204 ? of([]) : of(res))
    );
  }

  public putRequest(url, body): Observable<any> {
    return this.httpClient.put(url, body, {headers: this.headers});
  }

  downloadPDF(url: string): Observable<Blob> {
    return this.httpClient.get(this.SERVER_URL + url, { responseType: 'blob' , headers: this.headers})
  }
}
